<template>
  <div class="cr-card-detail-option">
    <v-icon>
      mdi-folder-outline
    </v-icon>

    <div
      class="d-flex flex-wrap align-center justify-space-between flex-grow-1"
    >
      <div class="d-flex flex-wrap" style="width: calc(100% - 60px)">
        <div class="cr-group" v-for="group in contactGroupList" :key="group.id">
          <v-chip label>{{ group.groupName }}</v-chip>
        </div>
      </div>

      <v-btn v-if="isEdit" small depressed color="primary" @click="editClick">
        수정
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-group {
  display: flex;
  padding: 2px 4px 2px 0px;

  .v-chip {
    height: 28px !important;
  }
}
</style>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data({ $store: { getters } }) {
    const { contactGroupList } = getters["cardDetail/detail"];
    return { contactGroupList: contactGroupList || [] };
  },
  computed: {
    ...mapGetters("cardDetail", ["isEdit", "detail"]),
    contactGroupIdList() {
      return this.contactGroupList.map(c => c.id);
    }
  },
  methods: {
    ...mapMutations("contactDialog", ["SET_DIALOG"]),
    ...mapActions("group", ["getPrivateContactGroupTree"]),
    getData() {
      if (!this.isEdit) return;
      return { contactGroupIdList: this.contactGroupIdList };
    },
    async editClick() {
      const result = await this.getPrivateContactGroupTree();
      if (result) {
        this.SET_DIALOG({
          title: this.$t("contact.17"),
          type: "copyCard",
          params: {
            isDetail: true,
            selectedItems: [
              {
                id: this.detail.id,
                contactGroupIdList: this.contactGroupIdList
              }
            ]
          },
          confirmCallbackFunc: contactGroupList => {
            this.contactGroupList = contactGroupList;
          }
        });
      }
    }
  }
};
</script>
